import React, { } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { theme } from '../../theme';
import { GlobalCSS } from '../../assets/css/style';
import { GridCSS } from '../../assets/css/grid';
import HeaderOne from './header/header-one';
import HeaderTwo from './header/header-two';
import Footer from './footer';
import PageScrollBar from './page-scroll-bar';
import Client from './client';
import '../../assets/fonts/themify-icons.css';
import '../../assets/fonts/fonts.css';
import '../../assets/css/animation.css';
import '../../assets/css/animate.min.css';

const Layout = ({ children, ...props }) => {
	const { headerStyle, headerLayout } = props;

	return (
		<ThemeProvider theme={theme}>
			<GlobalCSS />
			<GridCSS />
			<PageScrollBar />
			<Client />
			<div id="wrpper" className="wrapper">
				{headerLayout !== 2 && <HeaderOne layout={headerStyle} />}
				{headerLayout === 2 && <HeaderTwo layout={headerStyle} />}
				<main className="page-content">
					{children}
				</main>
				<Footer />
			</div>
			<div className='cursor' id="cursor"></div>
			<div className='cursor2' id="cursor2"></div>
			<div className='cursor3' id="cursor3"></div>
		</ThemeProvider>
	)
}

Layout.propTypes = {
	children: PropTypes.node.isRequired
}

export default Layout;